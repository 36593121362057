// We also have this same stuff in ruby-land - see app.rb - App.add_url_params
// Use this when you need to do a similar thing but client-side
export default function addParamsToUrl(url, params = {}) {
  const host = location.protocol + '//' + location.host
  // url can be path sometimes
  const urlObject = new URL(url, host)
  if (params) {
    const paramsArray = []
    Object.keys(params).forEach((key) => {
      // Don't add duplicate keys, if key is already present in the URL param skip it
      if (!urlObject.searchParams.has(key)) {
        paramsArray.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
      }
    })

    const urlParts = url.match(/^([^#]*)(#.*)?$/)
    const nonFragment = urlParts[1]
    const fragment = urlParts[2] || ''

    if (fragment.startsWith('#/')) {
      url = `${nonFragment}${addQueryParamsToString(fragment, paramsArray)}`
    } else {
      url = `${addQueryParamsToString(nonFragment, paramsArray)}${fragment}`
    }
  }
  return url
}

function addQueryParamsToString(string, params) {
  if (params.length > 0) {
    string += `${string.indexOf('?') === -1 ? '?' : '&'}${params.join('&')}`
  }
  return string
}
