import $ from 'jquery'

/*
   Sets a scrolltop class on <body> when you're scrolled to the top - to be used by CSS
   e.g to make the header transparent based on the site's settings or add shadows/etc.
 */
const updateBodyScrollTop = function () {
  const threshold = $('body').data('scrolltop-threshold') ?? 49
  const scrollTop = $(window).scrollTop()
  $('body')
    .addClass('scrolltop-listener-initialized')
    .toggleClass('scrolltop', scrollTop <= threshold)
}

$(window).on('scroll load resize', updateBodyScrollTop)
